
export const environment = {
  production: false,
  devToolbar: false,
  developmentMode: true,
  xrayMode: true,
  appName: 'myaccountcsr',
  brandingUrl: null,
  customerFormsUrl: '',
  accountCheckEndpoint :'',
  myAccountUrl: '',

  iam: {
    homePage: '/page/login',
    secureHomePage: '/csrapp/home',
    nonSecureURI: ['/iam/login', '/login', '/page/login'],
    loginUrl: '/page/login',
    logoutUrl: '/logout',
    tokenEndPoint: '',
    endPoint: '',
    sessionTokenKey: 'qa-iam-token',
    supportedRoles: ['CSR'],
  },

  account: {
    endPoint: ''
  },
  accountservice: {
    endPoint: ''    
  },
  customerservice: {
    endPoint: ''
  },
  documentservice: {
    endPoint: ''
  },
  adminservice: {
    legacyEndPoint: '',
        endPoint: ''
    
  },  

  security: {
    recaptcha: {
      siteKey: 'XXXXX'
    },
    google_client_id: {
      id: 'XXXXX'
    }
  },

  googleAnalytics: {
    domain: 'none',
    trackingId: ''
  },
  /*** Need to replace below api calls with utility api angular lib services... ***/
  customerApiEndPoint: '',
  bnpApiEndPoint: '',
  customerSearchApiEndPoint: '',
  postErrorEndpoint:''
};